import React from 'react';

import { FormattedMessage } from 'react-intl';
import Layout from '../components/fr';
import SEO from '../components/seo';
import Scroll from '../components/scroll';

import RecentPostsMD from '../components/blog-md/recent-posts-fr';
import TeamFeaturedMD from '../components/team-md/team-featured-fr';
import AwardsMD from '../components/awards/awards-fr';
import FeaturedProductsMD from '../components/products/featured-fr';

const IndexPage = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Furlan Snowboards - Site officiel et shop en ligne"
      description="Basé en Isère, toutes nos boards ont quelque chose en plus pour la poudre, en supplément de leur programme initial"
      pathname="/fr/"
      keywords={['snowboard', 'boards', 'polyvalence']}
    />
    <div className="indexPage">
      <div className="alert alert-secondary" role="alert" style={{marginTop: '20px'}}>
        <center>🎁 <strong>-300€ SI TU TROUVES UN POTE QUI ACHETE UNE 2EME BOARD AVEC TOI</strong> 🎁<br />🎉 <strong>Code: yepoto</strong> -150€ sur chaque board à la commande! 🎉</center>
      </div>
      <FeaturedProductsMD />
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-10 offset-lg-1 header-content my-auto">
            <h1 className="mb-4"><br /><br /><br />À LA UNE !</h1>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <p>
                Ci-dessous en vidéo: Board Aaron Swartz tribute 163. Pour certains il était un criminel, pour nous il était un héros pour l’humanité, un rebel. C’est ce qui nous rapproche de lui, nous les glisseurs en travers, son attitude anti conformiste.
                </p>
                <p>
                Qui dit board culture dit surf, neige, eau, béton. Qui dit rebel, dit liberté, style, art, musique ! La Furlan Family, c’est du fun, des snowboarders, des musiciens, écrivains, photographes, filmeurs, des enfants, des ado, un petit peu de cascades mais pas trop, on préfère l’esthétique.  
                </p>
                <p>
                Le snowboard c’est un tout. Et on est fier d’en faire partie. Rejoins nous ! 
Furlan Snowboards.<br /><br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-background">
        <div className="video-foreground">
          <iframe src="https://www.youtube.com/embed/O3M8QZYRR-M" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <section className="cta mt-5">
        <div className="cta-content">
          <div className="container">
            <div className="col-lg-10 offset-lg-2 mb-3">
              <h2>
                Le truc en plus dans la poudre
              </h2>
            </div>
          </div>
          <div className="container-fluid">
            <div className="col-lg-12">
              <AwardsMD />
            </div>
          </div>
        </div>
      </section>
      <blockquote className="blockquote-credit">
        @thereivolution (Shawn Rei) / Clem Nell
      </blockquote>
      <TeamFeaturedMD />
      <hr />
      <RecentPostsMD />
    </div>
  </Layout>
);

export default IndexPage;
